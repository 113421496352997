import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import { Hub } from '@jelly/templates'

const Index = ({ data: { props }, pageContext: { canonical, moduleRiverOfLinks, mainContent, page, search } }) => {
	props.moduleRiverOfLinks = { ...moduleRiverOfLinks, mainContent, search, tab: 'article|category|question' }
	props.meta.canonical = canonical
	props.canonical = canonical
	props.page = page

	return <Hub {...props} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	query hub($slug: String!, $preview: Boolean) {
		props: hub(slug: $slug, preview: $preview) {
			displayModified
			title
			subtitle
			href
			adsense
			adsenseMobile
			channels {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			qtOfferGroupId: offerGroupId
			structuredData
			title
			path
			isCurated
			meta {
				robots
				description
				title
			}
			moduleArticles {
				title
				articles {
					author {
						thumbnail: avatar
						displayName
						user {
							imagePath
						}
					}
					editorsPick
					sponsorship {
						avatar
						displayName
						sponsorType
					}
					isSponsored
					canonical
					title
					thumbnail {
						path
					}
				}
			}
			moduleTrendingArticles {
				title
				articles {
					author {
						thumbnail: avatar
						displayName
						user {
							imagePath
						}
					}
					editorsPick
					canonical
					sponsorship {
						avatar
						displayName
						sponsorType
					}
					isSponsored
					title
					subtitle
					thumbnail {
						path
					}
				}
			}
			moduleBestPicks {
				title
				categories {
					cardDescription
					canonical
					subtitle
					image
				}
			}
			sponsor {
				name: displayName
				logo: avatar(height: 400, width: 400)
			}
		}
	}
`
